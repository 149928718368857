import React from 'react'
import { Stack, Box } from '@mui/material';
import About from '../components/about';
import Merit from '../components/merit';
import Service from '../components/service';
import Works from '../components/works'
import Contact from '../components/contact';
import SnsLink from '../components/snsLink';
import Footer from '../components/footer';
import ScrollButton from '../components/Button/scrollButton';
import Top from '../components/top';

interface Props {
  isMobile : boolean;
}


const HomePage = ({isMobile}: Props) => {

  return (
    <Stack alignItems={'center'} bgcolor={'white'} position={'relative'} spacing={0}>
      {isMobile && <ScrollButton/>}
      {!isMobile && <SnsLink/>}
      <Top/>
      <About />
      <Merit />
      <Service isMobile={isMobile}/>
      <Works isMobile={isMobile}/>
      <Contact isMobile={isMobile} />
    </Stack>
  )
}

export default HomePage;
