import React from 'react';
import { Button, keyframes } from '@mui/material';

interface Props {
  title: string;
  color?: string;
  isTop?: boolean;
}

// @keyframesを使用してアニメーションを定義
const gradientAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
`;



const ApplyButton = ({color='linear-gradient(to right, #43cea2, #185a9d)', isTop=false}: Props) => {
  
  return (
    <Button
      onClick={() => {window.location.href="/apply"}}
      sx={{
        width: 'auto',
        background: color,
        color: 'white',
        fontSize:{
          xs: '16px',
          sm: '20px',
          md: '25px'
        },
        borderRadius: '50px',
        padding: {
          xs: '8px 24px',
          sm: '9px 26px',
          md: '10px 28px'
        },
        border:isTop ? 'none':'1px solid white',
        textTransform: 'none',
        fontWeight: 'normal',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        '&:hover': {
          background: 'linear-gradient(to right, #3cb9a0, #164e8d)',
          boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
          backgroundSize: '200% 200%', // グラデーションのサイズを2倍にしてアニメーションをスムーズにする
          animation: `${gradientAnimation} 10s ease infinite`,
          animationDirection: 'alternate', // アニメーションの再生方向を交互にする
        },
        backgroundSize: '200% 200%', // グラデーションのサイズを2倍にしてアニメーションをスムーズにする
        animation: `${gradientAnimation} 10s ease infinite`,
        animationDirection: 'alternate', // アニメーションの再生方向を交互にする
      }}
    >
      {isTop?
      '無料相談はこちら'
      :
      'お問い合わせ'
      }
      
    </Button>
  )
}

export default ApplyButton
