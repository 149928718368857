import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box, Stack, Typography, Theme } from '@mui/material';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import TitleWithLine from './titleWhiteLine';
import { categorySubStyle, customTheme, mainTitleStyle, messageStyle } from './style/style';
import YouTube from 'react-youtube';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
  isMobile: boolean;
}

// フォントファミリーの定義
const fontFamilies = {
  notoSansJP: "'Noto Sans JP', 'Helvetica', 'Arial', sans-serif",
};

const contentTitleStyle = (theme: Theme) => ({
  fontFamily: fontFamilies.notoSansJP,
  fontSize: {
    xs: '20px',
    sm: '22px',
    md: '24px',
    lg: '26px'
  },
  fontWeight: 700, // boldの代わりに数値で指定
  color: '#383E86',
});

const contentMessageStyle = (theme: Theme) => ({
  fontFamily: fontFamilies.notoSansJP,
  fontSize: {
    xs: '14px',
    sm: '14px',
    md: '15px',
    lg: '16px',
  },
  fontWeight: 500, // mediumの代わりに数値で指定
  color: 'black',
  lineHeight: {
    xs: '23px',
    sm: '27px',
    md: '32px',
  },
});


const AnimatedImage  = ({ videoId, width, opts }: { videoId: string; width: string; opts: any}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  // pc版
  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5 }}
      style={{ width }}
    >
       <YouTube videoId={videoId} 
       opts={opts}/>
    </motion.div>
  );
};


const AnimatedText = ({ children, delay = 1 }: { children: React.ReactNode; delay?: number }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5, delay }}
    >
      {children}
    </motion.div>
  );
};

const AnimatedWorkItemMobile = ({ children, direction, delay = 1 }: { children: React.ReactNode; direction: 'left' | 'right'; delay?:number }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: direction === 'left' ? -5 : 5 }}
      animate={inView ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 0.5, delay }}
    >
      {children}
    </motion.div>
  );
};

const Works = ({ isMobile }: Props) => {
  const theme = customTheme;

  // const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  // const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl')); 
  const stackRef = useRef<HTMLDivElement>(null);  
  const [stack_width, setWidth] = useState<number>(300)
  useLayoutEffect(()=>{
    if(stackRef.current) setWidth(stackRef.current.offsetWidth)
  }, [stackRef.current])

  const getSize = () => {
    if (isMd) return { width: '450', height: String(Math.round(450 * 0.5625)) };
    if (isLg) return { width: '520', height: String(Math.round(520 * 0.5625)) };
    if (isXl) return { width: '550', height: String(Math.round(550 * 0.5625)) };
    return { width: '450', height:String(Math.round(350 * 0.5625)) }; // デフォルト値
  };

  const { width, height } = getSize();

  const opts = {
    width,
    height,
    playerVars: {
      autoplay: 0,
    },
  };

   console.log(stack_width)

  return (
    <Box id="works" width={'100%'} bgcolor={'#F5FBFE'}>
      <Stack
        spacing={{
          xs: '4rem',
          sm: '3rem',
          md: '5rem',
        }}
        alignItems={'center'}
        sx={{
          px: {
            xs: '3rem',
            sm: '5rem',
            md: '5rem',
            lg: "7rem",
            xl: "12rem", 
          },
          py: {
            xs: '2rem',
            sm: '5rem',
            md: '8rem',
          },
        }}
      >
        <Stack pt={3} alignSelf={'flex-start'}>
          <TitleWithLine title="WORKS" />
          <Typography sx={{...categorySubStyle(theme), color:'#383E86', mb: { xs: '1rem', sm: '2rem', md: '3rem' }}}>過去事例のご紹介</Typography>
          <Typography sx={{...mainTitleStyle(theme), color:'#383E86', mb: { xs: '1rem', sm: '2rem', md: '3rem' }}}>
            「世界中の顧客にリーチし、{isMd ? <br/> : <></>}
            国際的な認知度を高める。」 
          </Typography>
          <Typography sx={{...messageStyle(theme), color:'#383E86', whiteSpace:"pre-wrap"}}>{`RE-IDEAは、クラウドファンディングに挑戦する企業を全力でサポートしています。${isMobile ? "" : "\n"}これまでの経験と知識を活かし、あなたの海外進出を成功させる準備が整っています。`}</Typography>
        </Stack>

        {isMobile ? (
          <Stack ref={stackRef} spacing={8} pb={8}>
            <AnimatedWorkItemMobile direction="right">
              <Stack  spacing={'1rem'} alignItems={'center'} >
                <Typography sx={contentTitleStyle(theme)}>日本発の伝統工芸品</Typography>
                <YouTube videoId={'7D_9d5oyqSM'} opts={{width: "100%", height:'auto'}} />
                <Typography sx={contentMessageStyle(theme)}>
                  このプロジェクトでは、日本の伝統工芸品を扱う企業が初めての海外クラウドファンディングに挑戦し、文化的なニュアンスの違いや市場特有の壁に直面していました。弊社は、掲載文の精密な校正と翻訳に加え、動画撮影・編集も自社で行い、海外の支援者により魅力的な訴求を行いました。
                </Typography>
              </Stack>
            </AnimatedWorkItemMobile>

            <AnimatedWorkItemMobile direction="left">
              <Stack spacing={'1rem'} alignItems={'center'}>
                <Typography sx={contentTitleStyle(theme)}>日本発のマッサージ器具</Typography>
                {/* widthは基本的に数値型のみサポートするらしい */}
                <YouTube videoId={'th8RXDnlSgU'}  opts={{width:"100%", height:'auto'}}/>
                <Typography sx={contentMessageStyle(theme)}>
                このプロジェクトでは、日本の整体院が初めての海外クラウドファンディングに挑戦し、慣れない英語圏の市場に挑戦しました。弊社は掲載文章や動画だけでなく、画像撮影・編集も自社で行い、より魅力的なプロジェクトになるよう心がけました。
                </Typography>
              </Stack>
            </AnimatedWorkItemMobile>
          </Stack>
        ) : (
          <Stack spacing={15}>
            <Stack direction={'row'} height={"100%"} spacing={{xs:'2rem', sm:'3rem', md: '3rem'}} alignItems={'center'} justifyContent={'center'}>
              <AnimatedImage videoId='7D_9d5oyqSM' width={"100%"} opts={opts}/>
              <Stack  spacing={{xs:'0.5rem', sm:'1.5rem', md: '1rem', lg:'1.5rem'}}>
                <AnimatedText>
                  <Typography sx={contentTitleStyle(theme)}>日本発の伝統工芸品</Typography>
                </AnimatedText>
                <AnimatedText delay={1.0}>
                  <Typography sx={contentMessageStyle(theme)}>
                    このプロジェクトでは、日本の伝統工芸品を扱う企業が初めての海外クラウドファンディングに挑戦し、文化的なニュアンスの違いや市場特有の壁に直面していました。弊社は、掲載文の精密な校正と翻訳に加え、動画撮影・編集も自社で行い、海外の支援者により魅力的な訴求を行いました。
                  </Typography>
                </AnimatedText>
              </Stack>
            </Stack>

            <Stack direction={'row'} spacing={{xs:'2rem', sm:'3rem', md: '3rem'}} alignItems={'center'} justifyContent={'center'}>
              <Stack spacing={{xs:'0.5rem', sm:'1.5rem', md: '1rem', lg:'1.5rem'}} >
                <AnimatedText>
                  <Typography sx={contentTitleStyle(theme)}>日本発のマッサージ器具</Typography>
                </AnimatedText>
                <AnimatedText delay={1.0}>
                  <Typography sx={contentMessageStyle(theme)}>
                    このプロジェクトでは、日本の整体院が初めての海外クラウドファンディングに挑戦し、慣れない英語圏の市場に挑戦しました。弊社は掲載文章や動画だけでなく、画像撮影・編集も自社で行い、より魅力的なプロジェクトになるよう心がけました。
                  </Typography>
                </AnimatedText>
              </Stack>
              <AnimatedImage videoId='th8RXDnlSgU' width='auto' opts={opts} />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default Works;