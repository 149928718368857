import { Theme } from '@mui/material/styles';
import { createTheme } from '@mui/material';

// カスタムブレークポイントを含むテーマを作成
export const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,  // 下限を900pxに設定
      lg: 1100,
      xl: 1250,
    },
  },
});

// フォントファミリーの定義
const fontFamilies = {
  jost: "'Jost', Arial, sans-serif",
  notoSansJP: "'Noto Sans JP', 'Helvetica', 'Arial', sans-serif",
};

// 共通のフォントウェイト
const fontWeights = {
  regular: 400,
  bold: 700,
};

export const categoryStyle = (theme: Theme) => ({
  fontFamily: fontFamilies.jost,
  fontWeight: fontWeights.bold,
  color: 'white',
  fontSize: {
    xs: '18px',
    sm: '20px',
    md: '24px',
    lg: '24px',
  },
});

export const categorySubStyle = (theme: Theme) => ({
  fontFamily: fontFamilies.notoSansJP,
  fontWeight: fontWeights.bold,
  color: 'white',
  fontSize: {
    xs: '14px',
    sm: '16px',
    md: '18px',
    lg: '18px',
  },
});

export const mainTitleStyle = (theme: Theme) => ({
  fontFamily: fontFamilies.notoSansJP,
  fontWeight: fontWeights.bold,
  color: 'white',
  fontSize: {
    xs: '25px',
    sm: '30px',
    md: '32px',
    lg: '38px',
  },
  lineHeight: {
    xs: '42px',
    sm: '56px',
    md: '64px',
  },
});

export const messageStyle = (theme: Theme) => ({
  fontFamily: fontFamilies.notoSansJP,
  fontWeight: fontWeights.bold,
  color: 'white',
  fontSize: {
    xs: '14px',
    sm: '16px',
    md: '18px',
    lg: '18px',
  },
  lineHeight: {
    xs: '25px',
    sm: '35px',
    md: '42px',
  },
});

// ApplyPageで利用するスタイル

export const applyTitleStyle = (theme: Theme) => ({
  fontFamily: fontFamilies.notoSansJP,
  fontSize: {
    xs: '25px',
    sm: '35px',
    md: '40px'
  },
  fontWeight: fontWeights.bold,
  color: '#383E86',
});

export const applyContentStyle = (theme: Theme) => ({
  fontFamily: fontFamilies.notoSansJP,
  fontSize: {
    xs: '16px',
    sm: '20px',
    md: '25px'
  },
  fontWeight: fontWeights.regular,
});

export const applySubStyle = (theme: Theme) => ({
  fontFamily: fontFamilies.notoSansJP,
  fontSize: {
    xs: '14px',
    sm: '16px',
    md: '20px'
  },
});

// フォント読み込み用のCSSを定義
export const globalFontStyles = `
  @font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/Jost-Regular.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/Jost-Bold.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/NotoSansJP-Regular.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/NotoSansJP-Bold.woff2') format('woff2');
  }
`;