import React, { useState, useEffect, useContext } from 'react';
import { Typography, Stack } from '@mui/material';
import { useIsMobile } from '../components/isMobile';


function PrivacyPolicy(){

  const isMobile = useIsMobile()

  const title1Style = {
    color: 'black',
    fontSize: isMobile ? "24px" : '28px',
    fontWeight: 700,
  };

  const title2Style = {
    color: 'black',
    fontSize: isMobile ? "18px" : '20px',
    fontWeight: 700,
  };
  
  const documentStyle = {
    color: 'black',
    fontSize: isMobile ? "14px" : '18px',
  };

  return (
    <Stack alignItems={'center'} bgcolor={'#F6F8FA'}>
      <Stack width={isMobile ? "85%" : '70%'} alignItems={'flex-start'} mt={8} mb={isMobile ? 15 : 20}  spacing={5}>
        <Typography style={{...title1Style}}>
          プライバシーポリシー
        </Typography>
        <Typography style={{...documentStyle}}>
          RE-IDEAでは（以下「当社」といいます）は、個人情報の取り扱いについて、以下の個人情報保護方針を定め、これに基づき個人情報の保護・管理を徹底してまいります。
        </Typography>

        <Stack bgcolor={'white'} px={isMobile ? undefined : 5} p={isMobile ? 3 : undefined}  py={isMobile ? undefined : 10} spacing={isMobile ? 3 : 5}>

        <Stack spacing={2}>
          <Typography style={{...title2Style}}>
            個人情報について
          </Typography>
          <Typography style={{...documentStyle}}>
            個人情報とは、個人情報の保護に関する法律（個人情報保護法）に規定される個人情報を指し、特定の個人を識別することができる情報、ならびに特定の個人と紐づいて使用されるメールアドレス、パスワード、クレジットカードなどの情報のことをいいます。
          </Typography>
        </Stack>

        <Stack spacing={2}>
          <Typography style={{...title2Style}}>
            個人情報の管理について
          </Typography>
          <Typography style={{...documentStyle}}>
            当社は、その管理下にある個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行います。当社が信頼に足ると判断し、個人情報の守秘義務契約を結んだ企業に個人情報の取り扱いを委託する場合がありますが、この場合にも個人情報は当社の個人情報保護方針に基づき保護されます。
          </Typography>
        </Stack>

        <Stack spacing={2}>
          <Typography style={{...title2Style}}>
            個人情報の利用目的について
          </Typography>
          <Typography style={{...documentStyle}}>
            当社では、お預かりした個人情報を、以下の目的のために利用させていただきます。
          </Typography>

          <Stack alignItems={'flex-start'} pl={3}>
              <Typography sx={{...documentStyle}}>
                ・RE-IDEA（以下「本サービス」といいます。）のサービスを提供するため
              </Typography>

              <Typography sx={{...documentStyle}}>
                ・本サービスを改善・改良、または新サービスを開発するため
              </Typography>

              <Typography sx={{...documentStyle}}>
                ・本サービスの機能・更新情報およびキャンペーンなど、最新情報のご案内（電子メール、SNS、その他ダイレクトメールの送付を含みます。）のため
              </Typography>
              <Typography sx={{...documentStyle}}>
                ・本サービスのメンテナンスや重要なお知らせなど、即時必要なご連絡のため
              </Typography>
              <Typography sx={{...documentStyle}}>
                ・本サービスに関する調査のご協力や各種イベントへのご参加をお願いするため
              </Typography>
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <Typography style={{...title2Style}}>
            個人情報の第三者への開示・提供について
          </Typography>
          <Typography style={{...documentStyle}}>
            当社は、以下に定める場合を除き、個人情報を第三者に提供しません。
          </Typography>

          <Stack  alignItems={'flex-start'} pl={3}>
              <Typography sx={{...documentStyle}}>
                ・お客様の同意がある場合
              </Typography>

              <Typography sx={{...documentStyle}}>
                ・お客様が希望されるサービスを行うために当社が業務を委託する業者に対して開示する場合
              </Typography>

              <Typography sx={{...documentStyle}}>
                ・法令に基づく場合
              </Typography>
          </Stack>


        </Stack>

        <Stack spacing={2}>
          <Typography style={{...title2Style}}>
            セキュリティについて
          </Typography>
          <Typography style={{...documentStyle}}>
            当社は、個人情報の正確性及び安全性確保のため、セキュリティに万全の対策を講じています。
          </Typography>
        </Stack>

        <Stack spacing={2}>
          <Typography style={{...title2Style}}>
            個人情報保護方針の改善について
          </Typography>
          <Typography style={{...documentStyle}}>
            当社プライバシーポリシーについては、その内容を適宜見直し、その改善に努めていきます。
          </Typography>
        </Stack>

        <Stack spacing={2}>
          <Typography style={{...title2Style}}>
            お客様の個人情報に関するお問い合わせ先
          </Typography>
          <Typography style={{...documentStyle}}>
            当社のお客様の個人情報に関するお問い合わせは、下記電子メールアドレスまでご連絡ください。
          </Typography>
          <Typography sx={{...documentStyle}}>RE-IDEA</Typography>
          <Stack direction={'row'} spacing={1}>
            <Typography sx={{...documentStyle}}>メールアドレス : info@re-idea.jp</Typography>
          </Stack>


        </Stack>

        </Stack>
      </Stack>
    </Stack>
  );
};

export default PrivacyPolicy;