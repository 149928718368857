import React from 'react';
import { Stack, Typography, Theme} from '@mui/material';
import { customTheme } from './style/style';

interface Props {
  number: string;
  title: string;
  content: string;
}

// フォントファミリーの定義
const fontFamilies = {
  notoSansJP: "'Noto Sans JP', 'Helvetica', 'Arial', sans-serif",
};

const numberStyle = (theme: Theme) => ({
  fontFamily: fontFamilies.notoSansJP,
  fontWeight: 900,
  background: 'linear-gradient(to right, #43CEA2 0%, #185A9D 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  color: 'transparent',
  display: 'inline-block',
  fontSize: {
    xs: '20px',
    sm: '28px',
    md: '48px',
  },
});

const titleStyle = (theme: Theme) => ({
  fontFamily: fontFamilies.notoSansJP,
  fontWeight: 600, // boldの代わりに数値で指定
  color: '#363636',
});

const contentStyle = (theme: Theme) => ({
  fontFamily: fontFamilies.notoSansJP,
  fontWeight: 400,
  color: '#363636',
  fontSize: {
    xs: '14px',
    sm: '14px',
    md: '18px',
  },
  lineHeight: {
    xs: '25px',
    sm: '35px',
    md: '42px',
  },
});

const MeritCarouselCard: React.FC<Props> = ({ number, title, content }) => {
  const theme = customTheme;
  const fontSize = 20;

  return (
    <Stack 
      height={{
        xs: '200px',
        sm: '260px',
        md: '350px',
      }}
      width={'100%'}
      spacing={{
        xs: '0.4rem',
        sm: '0.5rem',
        md: '1rem'
      }}
    >
      <Typography sx={numberStyle(theme)}>{number}</Typography>
      <Typography sx={{...titleStyle(theme), 
      fontSize: {
        xs: '20px',
        sm: '24px',
        md: '36px',
      },
      lineHeight:1.4,
      // // 高さ 1.4 * fontSize * 2行
      // height: {
      //   xs: 1.4 * 2 * 20,
      //   sm: 1.4 * 2 * 24,
      //   md: 1.4 * 2 * 34,
      // },
      }}>{title}</Typography>
      <Typography sx={contentStyle(theme)}>{content}</Typography>
    </Stack>
  );
};

export default MeritCarouselCard;