import React from 'react';
import { Box, Typography } from '@mui/material';
import { categoryStyle, customTheme } from './style/style';
interface TitleWithLineProps {
  title: string;
  isWhite?: boolean;
  width? : string;
}

const TitleWithLine: React.FC<TitleWithLineProps> = ({ title, isWhite=false, width, }) => {
  const theme = customTheme;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width:isWhite ? {xs: '80%', sm:'70%', md: '70%'} : {xs: '60%', sm:'60%', md: '40%'}, mb: 2 }} 
    alignSelf={isWhite ? 'flex-start' : 'none'}
    >
      <Typography 
        variant="h2" 
        component="h2" 
        sx={{ 
          ...categoryStyle(theme),
          color:isWhite?'white':'#383E86',
          mr: 2

        }}
      >
        {title}
      </Typography>
      <Box 
        sx={{ 
          flexGrow: 1, 
          height: '1px', 
          backgroundColor:isWhite?'white': '#383E86',
        }} 
      />
    </Box>
  );
};

export default TitleWithLine;