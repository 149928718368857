import React, { useCallback, useEffect, useState } from 'react'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'
import { EmblaOptionsType } from 'embla-carousel'
import "./css/BannerCarousel.css"
import { BannerCarouselDotButton, useDotButton } from './BannerCarouselDotButton'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
// import { CarouselButton } from '../Carousel/CarouselButton'
import { Stack } from '@mui/material'
import CarouselButton from './CarouselButton'


interface Props {
  slides: React.ReactNode[] // 変更: img_srcsの代わりにslidesを受け取る
  delay?: number;
  isMobile?: boolean;
}


const BannerCarousel: React.FC<Props> = ({ delay, slides, isMobile }) => {
  const options: EmblaOptionsType = { loop: true, skipSnaps: true, axis: 'x', dragFree: false}
  const [isPlaying, setIsPlaying] = useState(false)
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Autoplay({ 
      playOnInit: true, 
      delay: delay ? delay : 3000
    }),
    WheelGesturesPlugin({
    }),
  ])
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi)

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [wheelSelectedIndex, setWheelSelectedIndex] = useState(0)
  const [wheelScrollSnaps, setWheelScrollSnaps] = useState<number[]>([])

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  useEffect(() => {
    const autoplay = emblaApi?.plugins()?.autoplay
    const wheelScroll = emblaApi?.plugins()?.wheelGestures
    if (!autoplay || !wheelScroll) return

    // setIsPlaying(autoplay.isPlaying())
    emblaApi
      .on('autoplay:play', () => setIsPlaying(true))
      .on('autoplay:stop', () => setIsPlaying(false))
      // .on('reInit', () => setIsPlaying(autoplay.isPlaying()))

    const onSelect = () => {
      setWheelSelectedIndex(emblaApi.selectedScrollSnap())
      setPrevBtnEnabled(emblaApi.canScrollPrev())
      setNextBtnEnabled(emblaApi.canScrollNext())
    }

    setWheelScrollSnaps(emblaApi.scrollSnapList())
    emblaApi.on('select', onSelect)
    onSelect()

  }, [emblaApi])



  return (
    <div style={{position: 'relative'}} className="banner">
      <div className="banner__viewport" ref={emblaRef}>
        <div className="banner__container">
            {slides.map((slide, index) => (
              <div 
              className={`banner__slide ${
                index === selectedIndex
                  ? 'active'
                  : index === selectedIndex - 1 || index === selectedIndex + 1
                  ? 'adjacent'
                  : 'inactive'
              }`}  
              key={index}>
                <div className="banner__slide__number">
                  {slide}
                </div>
              </div>
            ))}
        </div>
      </div>

      <CarouselButton direction="prev" onClick={scrollPrev} />
      <CarouselButton direction="next" onClick={scrollNext} />

      <div className="banner__controls">
        <div className="banner__dots">
          {scrollSnaps.map((_, index) => (
            <BannerCarouselDotButton
              key={index}
              // onClick={() => onDotButtonClick(index)}
              className={'banner__dot'.concat(
                index === selectedIndex ? ' banner__dot--selected' : ''
              )}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default BannerCarousel