import React, { useState, useEffect, useCallback } from 'react';
import { Stack, Drawer, IconButton, List, ListItem, ListItemText, AppBar, Toolbar, Link as SnsLink, useMediaQuery,  Theme, Typography, ClickAwayListener } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {  Events, scrollSpy, scroller } from 'react-scroll';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import XIcon from '../img/sns/x_iconSP.png';
import NoteIcon from '../img/sns/note-iconSP.png'
import FacebookIcon from '../img/sns/fb_iconSP.png';
import InstagramIcon from '../img/sns/ig_iconSP.png';
import { customTheme } from './style/style';
// フォントファミリーの定義
const fontFamilies = {
  jost: "'Jost', 'Helvetica', 'Arial', sans-serif",
  poppins: "'Poppins', 'Helvetica', 'Arial', sans-serif",

};
const style = (theme: Theme) => ({
  color: '#383E86',
  fontSize: {
    xs: '18px',
    sm: '20px',
    md: '22px'
  }
});

const HeaderSP: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [fontColor, setFontColor] = useState<string>('white');
  const theme = customTheme;
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const location = useLocation();
  const navigate = useNavigate();

  const toggleDrawer = useCallback((newOpen: boolean) => {
    setOpen(newOpen);
  }, []);

  const handleClickAway = () => {
    setOpen(false);
  };

  const getIconSize = useCallback(() => {
    if (isXs) return '35px';
    if (isSm) return '50px';
    if (isMd) return '70px';
    return '60px'; // デフォルトサイズ (lg以上)
  }, [isXs, isSm, isMd]);

  const handleSetActive = useCallback((to: string) => {
    if(location.pathname === "/privacyPolicy"){
      setFontColor('#383E86')
    }else if(location.pathname === "/company"){
      setFontColor('#383E86')
    }
    if (location.pathname !== '/apply') {
      console.log('Active section:', to);
      if (to === 'merit') {
        setFontColor('#383E86')
      } else {
        setFontColor('white')
      }
    }
  }, [location.pathname]);

  const handleScroll = useCallback(() => {
    if (location.pathname !== '/apply') {
      const scrollPosition = window.scrollY;
      const meritSection = document.getElementById('merit');
      if (meritSection) {
        const meritPosition = meritSection.offsetTop;
        if (scrollPosition >= meritPosition - 100) {
          setFontColor('#383E86')
        } else {
          setFontColor('white')
        }
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if(location.pathname === "/privacyPolicy"){
      setFontColor('#383E86')
    }else if(location.pathname === "/company"){
      setFontColor('#383E86')
    }
    if (location.pathname === '/apply') {
      setFontColor('#383E86')
    } else {
      Events.scrollEvent.register('begin', handleSetActive);
      window.addEventListener('scroll', handleScroll);

      scrollSpy.update();

      return () => {
        Events.scrollEvent.remove('begin');
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [location.pathname, handleSetActive, handleScroll]);

  const handleNavigation = (section: string) => {
    if (location.pathname === '/apply') {
      navigate('/', { state: { scrollTo: section } });
    } else if (location.pathname === '/') {
      scroller.scrollTo(section, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -70,
      });
    }
    toggleDrawer(false);
  };

  const handleLogoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo: 'top' } });
    } else {
      scroller.scrollTo('top', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  };

  useEffect(() => {
    if (location.pathname === '/' && location.state && (location.state as any).scrollTo) {
      const section = (location.state as any).scrollTo;
      setTimeout(() => {
        scroller.scrollTo(section, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart'
        });
      }, 100);
    }
  }, [location]);


  return (
    <AppBar position="fixed" sx={{ bgcolor: 'transparent', transition: 'background-color 0.3s ease' }} elevation={0}>
      <Toolbar>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ width: '100%' }}>
          <Typography
            component="a"
            href="/"
            onClick={handleLogoClick}
            sx={{ 
              flexGrow: 1, 
              fontFamily: fontFamilies.poppins, 
              fontWeight: 'bold', 
              color: fontColor, 
              fontSize: {
                xs: '30px',  
                sm: '32px',  
                md: '36px',  
                lg: '40px',  
              },
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            RE-IDEA
          </Typography>
          <IconButton onClick={() => toggleDrawer(true)} color="inherit">
            <MenuIcon sx={{ color: fontColor, width: '27px', height: '27px' }} />
          </IconButton>
        </Stack>
      </Toolbar>
      <Drawer
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '50%',
          },
        }}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={() => toggleDrawer(false)}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" p={2}>
              <IconButton onClick={() => toggleDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <List>
              {['about',  'merit', 'service', 'works', 'contact'].map((section) => (
                <ListItem key={section} onClick={() => handleNavigation(section)}>
                  <ListItemText primary={section.charAt(0).toUpperCase() + section.slice(1)} sx={{ ...style(theme) }} />
                </ListItem>
              ))}
            </List>
            <Stack direction={'row'} mx={2} my={4} spacing={2} justifyContent={'center'}>
              <SnsLink href='http://www.facebook.com/share/WPmE2sgMqvUiL5Dg'>
                <img src={FacebookIcon} alt='Facebook' width={getIconSize()} />
              </SnsLink>
              <SnsLink href='http://www.instagram.com/re_idea.official'>
                <img src={InstagramIcon} alt='Instagram' width={getIconSize()} />
              </SnsLink>
              <SnsLink href='http://x.com/reidea_official'>
                <img src={XIcon} alt='X' width={getIconSize()} />
              </SnsLink>
              <SnsLink href='https://note.com/re_idea'>
                <img src={NoteIcon} alt='Note' width={getIconSize()} />
              </SnsLink>
            </Stack>
          </div>
        </ClickAwayListener>
      </Drawer>
    </AppBar>
  );
};

export default HeaderSP;