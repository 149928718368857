import {Box,  Stack, Typography} from '@mui/material';
import { categorySubStyle, mainTitleStyle, messageStyle } from './style/style';
import { customTheme } from './style/style';
import aboutIMG from '../img/aboutIMG.png'
import TitleWithLine
 from './titleWhiteLine';
import { useIsMobile } from './isMobile';



const About = () => {
  const theme = customTheme;
  const isMobile = useIsMobile()
  const customWidth = isMobile ? "84%" : '70%';

  
  return (
    <Box width={'100%'} zIndex={2} 
    sx={{position:'relative', margin:0, padding:0, top:-1}} id='about'>
      {/* 一番後ろを青く */}
      <Box width={'100%'} height={'100%'} sx={{position:'absolute', bgcolor:'transparent'}} />

      <Box  bgcolor={'#383E86'} width={'100%'}
      sx={{
        clipPath: `polygon(
          0% 0%,
          100% 0%,
          100% calc(100% - 10vw),
          0% 100%
        )`,
        backgroundImage : `url(${aboutIMG})`,
        backgroundSize: '90%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: {
          xs: 'center',
          sm: 'center',
          md: 'center 20%',
          lg: 'center',  
        },
      }}
      >
        <Stack alignItems={'center'}
        sx={{
          px: {
            xs: '1.0rem',
            sm: '3rem',
            md: '10rem'
          },
          pt: '4rem',
          pb: {
            xs: '4rem',
            sm: '6rem',
            md: '12rem'
          }
        }}>
          <Box width={customWidth}>
            <TitleWithLine title="ABOUT" isWhite={true}/>
            <Typography
            sx={{...categorySubStyle(theme),
              mb: {
                xs: '1rem',
                sm: '2rem',
                md: '3rem'
              },
              alignSelf:'flex-start'
            }}>私たちのミッション</Typography>
          </Box>
          <Typography
          width={customWidth}
          sx={{...mainTitleStyle(theme), whiteSpace:"pre-wrap",
            mb: {
              xs: '1rem',
              sm: '2rem',
              md: '3rem'
            }
          }}>{`「日本の素晴らしい技術を、世界へ届ける。」`}</Typography>
          <Typography 
          width={customWidth}
          sx={{
            ...messageStyle(theme), 
            mb: {
                xs: '2rem',
                sm: '2.5rem',
                md: '3rem'
            },
          }}>
            {!isMobile && "私たちRE-IDEAはあなたの技術を世界へ届けるお手伝いをする企業です。"}
            {!isMobile &&  <br />}
            {isMobile &&  "弊社には、"}実際にクラウドファンディングを成功させた経験者、海外在住経験と語学力を持つ者、海外クラウドファンディング支援の専門家など、多様な経験と専門知識を持つメンバーが揃っています。
            <br />
            {!isMobile && "この強力なチームが、海外進出におけるあらゆる課題に柔軟に対応し、あなたのプロジェクトを成功に導きます。ぜひ、私たちRE-IDEAと共に、あなたの商品を世界中に届け、夢を現実に変えましょう。"}</Typography>
        </Stack>
    </Box>
  </Box>
  )
}

export default About
