import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

interface CarouselButtonProps extends ButtonProps {
  direction: 'prev' | 'next';
}

const CarouselButton: React.FC<CarouselButtonProps> = ({ direction, onClick, ...props }) => {
  const Icon = direction === 'prev' ? ArrowLeftIcon : ArrowRightIcon;

  return (
    <Button
      className={`carousel-button carousel-button--${direction}`}
      onClick={onClick}
      sx={{
        color: 'white',
        background: 'linear-gradient(to right, #43CEA2 0%, #185A9D 100%)',
        minWidth: '30px',
        minHeight: '30px',
        width: {
          xs: '30px',
          sm: '35px',
          md: '40px'
        },
        height: {
          xs: '30px',
          sm: '35px',
          md: '40px'
        },
        borderRadius: '50%',
        padding: 0,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        [direction === 'prev' ? 'left' : 'right']: {
          xs: '10px',
          sm: '15px',
          md: '20px'
        },
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiSvgIcon-root': {
          fontSize: {
            xs: '38px',
            sm: '45px',
            md: '55px'
          },
        },
      }}
      {...props}
    >
      <Icon />
    </Button>
  );
};

export default CarouselButton;