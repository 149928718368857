import { Stack, Typography,  } from '@mui/material'
import React, {useState, ChangeEvent, useEffect} from 'react'
import { applySubStyle, applyContentStyle, applyTitleStyle, customTheme} from './style/style';
import ApplyTextField from './applyTextField';
import SendButton from './Button/sendButton';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import axios from "axios"

const Apply = () => {
  const theme = customTheme;

  const [userName, setUserName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [others, setOthers] = useState('');
  const [date1, setDate1] = useState<Dayjs|null>(null);
  const [date2, setDate2] = useState<Dayjs|null>(null);
  const [date3, setDate3] = useState<Dayjs|null>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setIsLoading] = useState(false);



  const checkFormValidity = (): void => {
    const isValid = !!(
      userName &&
      userName.trim() !== '' &&
      email &&
      email.trim() !== '' &&
      date1 !== null &&
      date2 !== null &&
      others &&
      others.trim() !== '' &&
      !(
        (date1 && date2 && date3) && 
        ((date1.isSame(date2) || date1.isSame(date3) || date2.isSame(date3)) ||
        (date1.isSame(date2, 'day') && date1.isSame(date2, 'hour') && date1.isSame(date2, 'minute')) ||
        (date1.isSame(date3, 'day') && date1.isSame(date3, 'hour') && date1.isSame(date3, 'minute')) ||
        (date2.isSame(date3, 'day') && date2.isSame(date3, 'hour') && date2.isSame(date3, 'minute')))
      )
    );
    setIsFormValid(isValid);
  };

  const changeUserName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserName(e.target.value);   
  };
  
  const changePhoneNumber = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPhoneNumber(e.target.value);
  };
  const changeEmail = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(e.target.value);
  };
  
  const changeOthers = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setOthers(e.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };

  
  const handleSendClick = async () => {
    try {
      setOpen(true);
      setIsLoading(true);
  
      // フォームデータをオブジェクトにまとめる
      const formData = {
        userName,
        phoneNumber,
        email,
        others,
        date1: date1 ? date1.toISOString() : null,
        date2: date2 ? date2.toISOString() : null,
        date3: date3 ? date3.toISOString() : null,
      };
  
      // APIリクエストを送信
      const response = await axios.post("https://api.overseas-cf.com", formData);
      // const response = await axios.post("http://localhost:5001/", formData);
      
      console.log('response!!!', response.data);
  
      // APIリクエストが成功した後にタイムアウトを設定
      setTimeout(() => {
        setIsLoading(false);
        setUserName('');
        setPhoneNumber('');
        setEmail('');
        setOthers('');
        setDate1(null);
        setDate2(null);
        setDate3(null);
      }, 2500);
  
    } catch (err) {
      console.error("エラー:", err);
      setIsLoading(false);
      alert("フォームの送信中にエラーが発生しました。もう一度お試しください。");
    }
  };

  useEffect(() => {
    checkFormValidity();
  }, [userName, email, others, date1, date2]);

  useEffect(() => {
    const storedValue = localStorage.getItem('editData');

    if (storedValue) {
      const parsedValue = JSON.parse(storedValue);
      setUserName(parsedValue.userName);
      setPhoneNumber(parsedValue.phoneNumber);
      setEmail(parsedValue.email);
      setOthers(parsedValue.others);
      setDate1(parsedValue.date1 ? dayjs(parsedValue.date1) : null);
      setDate2(parsedValue.date2 ? dayjs(parsedValue.date2) : null);
      setDate3(parsedValue.date3 ? dayjs(parsedValue.date3) : null);
    }
  }, []);

  useEffect(() => {
    const editData = {
      userName: userName,
      phoneNumber: phoneNumber,
      email: email,
      others: others,
      date1: date1,
      date2: date2,
      date3: date3,
    };

    if( userName ||  phoneNumber || email || others || date1 || date2 || date3) {
      localStorage.setItem('editData', JSON.stringify(editData));
    }
  }, [userName,  phoneNumber, email, others, date1, date2, date3]);
  

  console.log(date1)
  console.log(dayjs(date1).format('YYYY年MM月DD日 HH:mm'))


  return (
    <Stack width={{
      xs: '90%',
      sm: '80%',
      md: '60%',
      lg: '60%'
    }} alignItems={'center'} py={{xs:'6rem', sm: '8rem', md:'10rem'}} spacing={5}>
      <Stack width={'100%'} alignItems={'center'} spacing={3}>
        <Typography sx={{...applyTitleStyle(theme)}}>お問い合わせ</Typography>
        <Typography sx={{...applyContentStyle(theme)}}>まずは無料でご相談させていただくこともできますので、ご希望の場合は、下記のお打ち合わせご希望日時より、ご都合のつく日をご入力いただければ幸いです。</Typography>
      </Stack>
      <Stack spacing={3}  width={'100%'}>
        <ApplyTextField
        title="氏名"
        placeholder="例：山田太郎"
        name='userName'
        value={userName}
        onChange={changeUserName}
        isRequired={true}
        type='text'
        theme={theme}
        />
        <ApplyTextField
        title="電話番号"
        placeholder="0123-456-789"
        name='phoneNumber'
        value={phoneNumber} 
        onChange={changePhoneNumber}
        isRequired={false}
        type={'tel'}
        theme={theme}
        />
        <ApplyTextField
        title="Eメール"
        placeholder="abc@example.com"
        name='email'
        value={email} 
        onChange={changeEmail}
        isRequired={true}
        type='email'
        theme={theme}
        />
        <Stack spacing={2}>
          <Stack direction={'row'} spacing={1}>
            <Typography sx={{ ...applySubStyle(theme) }}>打ち合わせ希望日時</Typography>
            <Typography sx={{ ...applySubStyle(theme), color: 'red' }}>*</Typography>
          </Stack>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker 
              label="第1希望"
              value={date1}
              onChange={(newValue) => setDate1(newValue)}
              />
              <DateTimePicker 
              label="第2希望"
              value={date2}
              onChange={(newValue) => setDate2(newValue)}
              />
              <DateTimePicker 
              label="第3希望"
              value={date3}
              onChange={(newValue) => setDate3(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
          {(date1 && date2 && date3) && 
            ((date1.isSame(date2) || date1.isSame(date3) || date2.isSame(date3)) ||
            (date1.isSame(date2, 'day') && date1.isSame(date2, 'hour') && date1.isSame(date2, 'minute')) ||
            (date1.isSame(date3, 'day') && date1.isSame(date3, 'hour') && date1.isSame(date3, 'minute')) ||
            (date2.isSame(date3, 'day') && date2.isSame(date3, 'hour') && date2.isSame(date3, 'minute'))) && 
            <Typography color="error" sx={{ mt: 2 }}>
              希望日時が重複しています。異なる日時を選択してください。
            </Typography>
          }
        </Stack>
        <ApplyTextField
        title="お問い合わせ内容"
        placeholder="クラウドファンディングに掲載する商品を簡単にご記入ください。"
        value={others} 
        onChange={changeOthers}
        isRequired={true}
        type='text'
        rows={3}
        theme={theme}
        />
      </Stack>

      <Stack width={{
        xs: '50%',
        sm: '40%',
        md: '30%'
      }}>
          <SendButton 
          title='送信' 
          isDisable={!isFormValid} 
          open={open} 
          onClick={handleSendClick} 
          onClose={handleClose} 
          isLoading={loading}
          />
        </Stack>
    </Stack>
  )
}

export default Apply
