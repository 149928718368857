import React, { useState, useEffect, useContext } from 'react';
import { Typography, Stack, Divider } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { Link } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useIsMobile } from '../components/isMobile';

const SpecifiedCommercialTransaction: React.FC = () => {
  const isMobile = useIsMobile()

  const title1Style = {
    color: 'black',
    fontSize: isMobile ? "24px" : '28px',
    fontWeight: 700,
  };
  
  const title2Style = {
    color: 'black',
    fontSize: isMobile ? "18px" : '20px',
    fontWeight: 700,
  };
  
  const documentStyle = {
    color: 'black',
    fontSize: isMobile ? "14px" : '18px',
  };

  return (
    <Stack alignItems={'center'} bgcolor={'#F6F8FA'}>
      <Stack width={isMobile ? "85%" : '70%'} alignItems={'flex-start'} mt={8} mb={isMobile ? 15 : 20}  spacing={5}>
        <Typography style={{...title1Style}}>
          運営会社
        </Typography>

        <TableContainer component={Paper} sx={{boxShadow: 0}}>
          <Table >
            <TableBody>
              <TableRow>
                <TableCell width={isMobile ? "30%" : '20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                事業者名
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                株式会社RE-IDEA
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                運営統括責任者
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                代表取締役 中井涼祐
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                住所
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                〒151-0051 東京都渋谷区千駄ヶ谷1-30-10-4F
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                お問い合わせ先
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                TEL：070-1436-0803<br/>
                Mail：info@re-idea.jp
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                  設立
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                2024年5月31日
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                資本金
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                3,000,000円
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                ご利用料金
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                各種IDEA詳細ページをご覧ください。
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                引き渡し
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                募集期間終了日以降
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                その他お客様の負担する費用
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                  ・インターネット利用のために必要となる通信料及び接続料（金額は、お客様が契約した各事業者にお問い合わせください。）<br/>
                  ・購入時の決済手数料 0 %〜10 %<br/><br/>
                  返品・キャンセルについて：製品の性質上、原則、商品の返品・キャンセルはお受けしておりません。
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell width={'20%'} sx={{...documentStyle, borderRight:'1px solid #ededed'}} component="th" scope="row">
                事業内容
                </TableCell>
                <TableCell sx={{...documentStyle}}>
                  ・「IDEA実現→テストマーケティング→販売」までを一括で行うクラウドファンディングサイト「RE-IDEA」を中心とした各種支援サービスの運営<br/>
                  ・クラウドファンディング実行における各種コンサルティング業務<br/>
                </TableCell>
              </TableRow>



            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
};

export default SpecifiedCommercialTransaction;