import {Box, keyframes, Stack, Typography, } from '@mui/material';
import React from 'react'
import { categorySubStyle, customTheme, mainTitleStyle, messageStyle } from './style/style';
import ApplyButton from './Button/applyButton';
import TitleWithLine from './titleWhiteLine';
import nakai from '../img/footer_nakai.png'

interface Props{
  isMobile: boolean;
}

// キーフレームを使用してアニメーションを定義
const rainbowAnimation = keyframes`
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
`;


const Contact = ({isMobile}:Props) => {
  const theme = customTheme;

  return (
    <Stack id='contact' 
    sx={{
      background: 'linear-gradient(to right, #43CEA2 0%, #185A9D 100%)',
      width: '100%'
    }}
    direction={'row'}
    justifyContent={isMobile ? 'flex-start':'center'}
    >
        <Stack 
        alignItems={'flex-start'} px={'15rem'} py={'5rem'}
        sx={{
          px: {
            xs: '3rem',
            sm: '5rem',
            md: '3rem',
          },
          py: {
            xs: '2rem',
            sm: '3rem',
            md: '5rem'
          }
        }}>
          <TitleWithLine title='CONTACT' isWhite={true}/>
          <Typography
      
          sx={{...categorySubStyle(theme),
            mb: {
            xs: '1rem',
            sm: '2rem',
            md: '3rem'
          }}}>無料のご相談</Typography>
          {isMobile?
          <Typography
          sx={{...mainTitleStyle(theme), 
            justifySelf: 'center',
            mb: {
            xs: '1rem',
            sm: '2rem',
            md: '3rem'
          }}}>「海外進出への第一歩はここから。」</Typography>
          
        :
          <Typography
          sx={{...mainTitleStyle(theme), mb: {
            xs: '1rem',
            sm: '2rem',
            md: '3rem'
          }}}>「海外進出への<br/>第一歩はここから。」</Typography>
        }
          
          {!isMobile &&
            <Typography 
            sx={{
              ...messageStyle(theme),
              mb: {
                xs: '1rem',
                sm: '2rem',
                md: '3rem'
              }
            }}
            >RE-IDEAは、あなたの成功をサポートする準備ができています。
            <br/>
            小さな質問から大きなプロジェクトまで、何でもご相談ください。
            <br/>
            まずは無料のご相談から、お気軽にお問い合わせください。
            </Typography>
          }
          <ApplyButton title='お問い合わせ'/>
        </Stack>
        {!isMobile && 
          <Box
            alignSelf={'center'}
            component="img"
            src={nakai}
            alt='代表取締役「中井涼祐」の画像'
            sx={{
              width: 'auto',
              height:'30%',
              objectFit: 'cover',
              position: 'relative',
              overflow: 'hidden',
              borderRadius: '50%', // 丸くするためにborderRadiusを50%に設定
              right: '10px',
              zIndex: 2,
            }}
          />
        }
    </Stack>
  )
}

export default Contact
