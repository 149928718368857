import { useState } from 'react';
import React from 'react';
import { applySubStyle} from './style/style';
import { Stack, Typography, TextField, Theme, useMediaQuery } from '@mui/material';

interface Props {
  title: string;
  placeholder: string;
  value: string;
  name?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired: boolean;
  rows?: number;
  type: 'text' | 'email' | 'tel';
  theme: Theme;
}

const ApplyTextField: React.FC<Props> = ({ title, placeholder, value, onChange, type = 'text', isRequired=false, name, rows=1, theme}) => {

  const [error, setError] = useState<string | null>(null);
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));

  const getFontSize = () => {
    if (isXs) return '12px';
    if (isSm) return '14px';
    if (isMd) return '16px';
    return '18px';
  };

  const validateInput = (value: string) => {
    switch (type) {
      case 'email':
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(value) ? null : 'メールアドレスの形式が正しくありません';
      case 'tel':
        const telRegex = /^\d{3,4}-\d{3,4}-\d{3,4}$/;
        return telRegex.test(value) ? null : '電話番号は半角数字とハイフンのみ使用可能です';
      default:
        return null;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const validationError = validateInput(newValue);
    setError(validationError);
    onChange(event);
  };


  return (
    <Stack spacing={2} width={'100%'}>
      <Stack direction={'row'} spacing={1}>
        <Typography sx={{ ...applySubStyle(theme) }}>{title}</Typography>
        {isRequired &&
          <Typography sx={{ ...applySubStyle(theme), color: 'red' }}>*</Typography>
        }
      </Stack>
      <TextField 
        placeholder={placeholder}
        name={name} 
        rows={rows}
        value={value} 
        onChange={handleChange}
        multiline={type === 'text'}
        type={type}
        error={!!error}
        helperText={error}
        required={isRequired}
        InputProps={{
          style: {
            fontSize: getFontSize(),
          },
        }}
      />
    </Stack>
    
  );
};

export default ApplyTextField;