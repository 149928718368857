import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Stack, Link as PageLink,  useMediaQuery, Theme, keyframes } from '@mui/material';
import { styled } from '@mui/system';
import { Link, Events, scrollSpy, animateScroll as scroll } from 'react-scroll';
import { useLocation, useNavigate } from 'react-router-dom';
import { customTheme } from './style/style';

// @keyframesを使用してアニメーションを定義
const gradientAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
`;

// フォントファミリーの定義
const fontFamilies = {
  jost: "'Jost', 'Helvetica', 'Arial', sans-serif",
  poppins: "'Poppins', 'Helvetica', 'Arial', sans-serif",

};

const StyledAppBar = styled(AppBar)<{ bgcolor: string }>(({ bgcolor }) => ({
  backgroundColor: bgcolor,
  boxShadow: 'none',
  transition: 'background-color 0.3s ease',
}));




const Header: React.FC = () => {
  const theme = customTheme;
  const [fontColor, setFontColor] = useState<string>('white');
  const location = useLocation();
  const navigate = useNavigate();

  const NavButton = styled(Button)(({ theme}: { theme: Theme }) => ({
    fontWeight: 'regular',
    color: fontColor,
    fontFamily: fontFamilies.jost,
    
    [theme.breakpoints.up('xs')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '22px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '24px',
    },
  }));

  useEffect(() => {
    if (location.pathname === '/apply') {
      setFontColor('#383E86');
    } else {
      const handleScroll = () => {
        if (location.pathname !== '/apply') {
          const scrollPosition = window.scrollY;
          const meritSection = document.getElementById('merit');
          if (meritSection) {
            const meritPosition = meritSection.offsetTop;
            // メリットセクションの上端に到達したときに色を変更
            if (scrollPosition >= meritPosition) {
              setFontColor('#383E86');
            } else {
              setFontColor('white');
            }
          }
        }
      };

      window.addEventListener('scroll', handleScroll);
      // 初期状態を設定
      handleScroll();

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [location.pathname]);

  const handleNavigation = (section: string) => {
    if (location.pathname === '/apply') {
      navigate('/', { state: { scrollTo: section } });
    }
  };

  const handleLogoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo: 'top' } });
    } else {
      scroll.scrollToTop({ smooth: true, duration: 500 });
    }
  };


  useEffect(() => {
    if(location.pathname === "/privacyPolicy"){
      setFontColor('#383E86')
    }else if(location.pathname === "/company"){
      setFontColor('#383E86')
    }
    if (location.pathname === '/' && location.state && (location.state as any).scrollTo) {
      const section = (location.state as any).scrollTo;
      setTimeout(() => {
        const element = document.getElementById(section);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    }
  }, [location]);

  const getLinkProps = (section: string) => {
    const isApplyPage = location.pathname === '/apply';
    return {
      to: section,
      spy: !isApplyPage,
      smooth: !isApplyPage,
      duration: 500,
      onClick: () => handleNavigation(section),
    };
  };

  return (
    <Box display={'flex'}>
      <StyledAppBar 
        position="fixed" 
        bgcolor={'transparent'}
        sx={{
          px: {
            xs: '0.5rem',
            sm: '0.75rem',
            md: '1.0rem', 
            lg: '1.25rem',
            xl: '1.5rem'
          }
        }}
      >
        <Toolbar>
          <Stack width={'100%'} direction={'row'} justifyContent={'space-between'}>
            <Box 
              component="a" 
              href="/" 
              onClick={handleLogoClick}
              sx={{ textDecoration: 'none', cursor: 'pointer' }}
            >
              <Typography 
                variant="h6" 
                component="div" 
                sx={{ 
                  flexGrow: 1, 
                  fontFamily: fontFamilies.poppins, 
                  fontWeight: 'bold', 
                  color:fontColor, 
                  fontSize: {
                    xs: '24px',  
                    sm: '32px',  
                    md: '36px',  
                    lg: '40px',  
                  },
                }}
              >
                RE-IDEA
              </Typography>
            </Box>
            <Stack direction={'row'}>
              {['about',  'merit', 'service','works', 'contact'].map((section) => (
                  <NavButton key={section} theme={theme}>
                    {section ==='contact'?
                      <Box 
                      sx={{
                        width: 'auto',
                        background: 'linear-gradient(to right, #43cea2, #185a9d)',
                        color: 'white',
                        
                        borderRadius: '50px',
                        padding:'2px 20px',
                        border:'none',
                        textTransform: 'none',
                        fontWeight: 'normal',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        backgroundSize: '200% 200%', // グラデーションのサイズを2倍にしてアニメーションをスムーズにする
                        animation: `${gradientAnimation} 10s ease infinite`,
                        animationDirection: 'alternate', // アニメーションの再生方向を交互にする
                      }}>
                        <Typography sx={{ fontStyle: 'italic', fontSize:'20px', }}>
                          <Link {...getLinkProps(section)}>
                            {section.toUpperCase()}
                          </Link> 
                        </Typography>
                      </Box>
                      :
                      <Link {...getLinkProps(section)}>
                        {section.toUpperCase()}
                      </Link>
                    }
                  </NavButton>
              ))}
            </Stack>
          </Stack>
        </Toolbar>
      </StyledAppBar>
    </Box>
  );
};

export default Header;