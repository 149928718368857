import React from 'react'
import { Button } from '@mui/material'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

const ScrollButton = () => {

  const scrollRef = React.createRef<HTMLDivElement>()

  const scrollToTop = () => {
    if(scrollRef !== null && scrollRef?.current){
        const elementTop = scrollRef.current.getBoundingClientRect().top;
        window.scrollBy({ top: elementTop , behavior: 'smooth' });
    }else{
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    } 
  };

  return (
    <Button sx={{
      zIndex: 10,
      position: 'fixed',
      bottom: '30px',  // 下端からの距離
      right: '30px',   // 右端からの距離
      minWidth: 'auto',  // ボタンの最小幅を削除
      padding: '12px',  // アイコンの周りにパディングを追加
      borderRadius: '50%',  // 円形のボタンにする
      backgroundColor: '#383E86',  // 背景色を白に
      boxShadow: '0 2px 10px rgba(0,0,0,0.2)', 
      '&:hover': {
        backgroundColor: '#383E86',  // ホバー時の背景色
      }
    }}
    onClick={scrollToTop}
    >
      <KeyboardDoubleArrowUpIcon fontSize='medium' sx={{color: 'white'}}/>
    </Button>
  )
}

export default ScrollButton
