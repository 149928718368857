import React from 'react';
import { Routes, Route } from "react-router-dom";
import HomePage from '../pages/home-page';
import ApplyPage from '../pages/apply-page';
import Header from '../components/header';
import { useMediaQuery,} from '@mui/material';
import HeaderSP from '../components/headerSP';
import { CssBaseline, ThemeProvider, GlobalStyles } from '@mui/material';
import Footer from '../components/footer';
import { customTheme, globalFontStyles } from '../components/style/style';
import PrivacyPolicy from '../pages/privacyPolicy';
import SpecifiedCommercialTransaction from '../pages/specifiedCommercialTransaction';

const Router = () => {

  const theme = customTheme;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  return (
    <div id='router' style={{minWidth:!isMobile ? '1024px' : undefined}}>
      {isMobile ? <HeaderSP/> : <Header/>}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles styles={globalFontStyles} />
          <Routes>
            <Route path="/" element={<HomePage isMobile={isMobile} />} />
            <Route path="/apply" element={<ApplyPage/>} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy/>} />
            <Route path="/company" element={<SpecifiedCommercialTransaction/>} />
          </Routes>
        <Footer isMobile={isMobile}/>
      </ThemeProvider>
    </div>
  );
};

export default Router;