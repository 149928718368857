import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import ApplyButton from './Button/applyButton';
import topImg from '../img/top_img.png';
import { Theme } from '@mui/material/styles';
import { customTheme } from './style/style';
import { useIsSP } from './isSP';

// フォントファミリーの定義
const fontFamilies = {
  notoSansJP: "'Noto Sans JP', 'Helvetica', 'Arial', sans-serif",
};

const mainTitleStyle = (theme: Theme) => ({
  fontFamily: fontFamilies.notoSansJP,
  fontWeight: 700, // boldの代わりに数値で指定
  color: 'white',
  fontSize: {
    xs: '30px',
    sm: '48px',
    md: '64px',
    lg: '64px'
  },
  textShadow: '2px 2px 4px rgba(0,0,0,0.5)', // テキストの視認性を高めるためのシャドウ
});

const messageStyle = (theme: Theme) => ({
  fontFamily: fontFamilies.notoSansJP,
  fontWeight: 500, // mediumの代わりに数値で指定
  color: 'white',
  fontSize: {
    xs: '16px',
    sm: '18px',
    md: '24px',
  },
  lineHeight: {
    xs: '40px',
    sm: '55px',
    md: '62px',
  },
  textShadow: '1px 1px 2px rgba(0,0,0,0.5)', // テキストの視認性を高めるためのシャドウ
});

const Top: React.FC = () => {
  const theme = customTheme;
  const isSP = useIsSP()

  return (
    <Box width={'100%'} sx={{position:'relative', margin:0, padding:0}} id='top'>
      {/* 一番後ろを青く */}
      <Box width={'100%'} height={'100%'} sx={{position:'absolute', bgcolor:'#383E86'}}></Box>
      <Box
        sx={{
          clipPath: `polygon(
            0% 0%,
            100% 0%,
            100% calc(100% - 5vw),
            0% 100%
          )`,
          width: '100%',
          height: {
            xs: 'calc(85vh - 56px)', 
            sm: 'calc(85vh - 64px)',
            md: '95vh',
          },
          backgroundImage: `linear-gradient(to right, rgba(56, 62, 134, 0.46), rgba(56, 62, 134, 0.46)), url(${topImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
          spacing={{
            xs: '1.5rem',
            sm: '2rem',
            md: '3rem'
          }}
          sx={{
            height: '100%',
            position: 'relative',
            zIndex: 1,
            px:{
              xs: '2rem',
              sm: '3rem',
              md: '5rem'
            },
            py:{
              xs: '2rem',
              sm: '3rem',
              md: '5rem'
            },
            pt:{
              xs: '4rem',
              sm: '5rem',
              md: '6rem'
            }
          }}
        >
          <Typography sx={mainTitleStyle(theme)}>
            世界へ、その一歩を。
          </Typography>
          <Stack spacing={'1rem'} alignItems={'center'}>
            <Typography sx={{...messageStyle(theme), textAlign:"center", whiteSpace:"pre-wrap"}}>
              {`日本企業の海外クラウドファンディングを、${isSP ? "\n" : "\n"}私たちが徹底的にサポートします。`}
            </Typography>
          </Stack>
          <Stack pt={2}>
            <ApplyButton title='無料相談はこちら' isTop={true}/>
          </Stack>

        </Stack>
      </Box>
    </Box>
  );
};

export default Top;