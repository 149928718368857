import React from 'react';
import { Box, Stack, Typography, Theme, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {  categorySubStyle, mainTitleStyle, messageStyle } from './style/style';
import TitleWithLine from './titleWhiteLine';
import titlePNG from '../img/service_title.png';
import service1 from '../img/service1.png';
import service2 from '../img/service2.png';
import service3 from '../img/service3.png';
import { customTheme } from './style/style';
import { useIsMobile } from './isMobile';
import { useIsSP } from './isSP';


interface Props {
  isMobile: boolean;
}

// フォントファミリーの定義
const fontFamilies = {
  notoSansJP: "'Noto Sans JP', 'Helvetica', 'Arial', sans-serif",
};

const contentTitleStyle = (theme: Theme) => ({
  fontFamily: fontFamilies.notoSansJP,
  fontWeight: 700, // boldの代わりに数値で指定
  color: '#383E86',
  fontSize: {
    xs: '20px',
    sm: '22px',
    md: '24px',
    lg: '26px'
  },
});

const contentMessageStyle = (theme: Theme) => ({
  fontFamily: fontFamilies.notoSansJP,
  fontWeight: 500, // mediumの代わりに数値で指定
  color: 'black',
  fontSize: {
    xs: '14px',
    sm: '14px',
    md: '15px',
    lg: '16px',
  },
  whiteSpace:"pre-wrap",
  lineHeight: {
    xs: '23px',
    sm: '27px',
    md: '32px',
  },
});

const AnimatedImage = ({ src, alt, width, style }: { src: string; alt: string; width: string, style?: React.CSSProperties; }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (

    <motion.img
      ref={ref}
      src={src}
      alt={alt}
      width={width}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5 }}
      style={{
        filter:'drop-shadow(10px 10px 0 #F5FBFE)',
        ...style,
      }}
    />
  );
};

const AnimatedText = ({ children, delay = 1.0 }: { children: React.ReactNode; delay?: number }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5, delay }}
    >
      {children}
    </motion.div>
  );
};

const AnimatedServiceItemMobile = ({ children, direction, delay = 1.0 }: { children: React.ReactNode; direction: 'left' | 'right'; delay?:number }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: direction === 'left' ? -5 : 5 }}
      animate={inView ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 0.5, delay }}
    >
      {children}
    </motion.div>
  );
};

const Service = ({ isMobile }: Props) => {
  const theme = customTheme;
  const isSP = useIsSP()

  const translate_text = isMobile ? "元の日本語の文章を丁寧に校正し、帰国子女による翻訳を行うことで、ターゲットとなる海外市場に最適な表現を行います。この過程で、文化的なニュアンスや市場特有の言い回しを考慮することで、プロジェクトの魅力を最大化させます。"
                              : `クラウドファンディングにおける掲載文は、プロジェクトの第一印象や信頼性、プロジェクトの魅力を効果的に伝え、支援者とのコミュニケーションを促進する上で非常に重要です。\nそこで我々は、元の日本語の文章を丁寧に校正し、帰国子女による翻訳を行うことで、ターゲットとなる海外市場に最適な表現を行います。この過程で、文化的なニュアンスや市場特有の言い回しを考慮することで、プロジェクトの魅力を最大化させます。`
  
  const communicate_text = isMobile ? `クラウドファンディングサイトへの情報登録や審査申請、支援者からの問い合わせ対応を全面的にサポートします。迅速かつ誠実な対応を行うことにより、支援者とのコミュニケーションを円滑に進めることができます。`
                                : "クラウドファンディングにおける運営や支援者とのやり取りは、プロジェクトの円滑な進行や支援者との信頼関係構築において非常に重要です。我々は、クラウドファンディングサイトへの情報登録や審査申請、支援者からの問い合わせ対応を全面的にサポートします。この過程で迅速かつ誠実な対応を行うことにより、支援者とのコミュニケーションを円滑に進めることができ、プロジェクトの効果を最大化することができます。"
  
  const delivery_text = isMobile ? `海外発送には、複雑な関税や配送料の問題が伴います。我々は、これらの調査を徹底的に行い、適正な料金設定とスムーズな発送手続きをサポートします。`
                             : "海外発送には、複雑な関税や配送料の問題が伴います。我々は、これらの調査を徹底的に行い、適正な料金設定とスムーズな発送手続きをサポートします。具体的には、関税や配送料の詳細な情報を提供し、それに基づいて最適な料金プランを提案することで、無駄なコストを削減し、プロジェクトの成功を支援します。"
  

  const textComponent = (title:string, src:string, description:string, reverse?: boolean, alt?: string) => {
    return(
      isSP ? 
      <Stack spacing={{xs:'1rem', sm: '1.5rem', lg:"4rem"}} alignItems={'center'}>
        <Typography width={"100%"} sx={{...contentTitleStyle(theme), textAlign:"left"}}>{title}</Typography>
        <Box
          component="img"
          src={src}
          alt='img'
          width={'100%'}
          sx={{
            aspectRatio:"16/9",
            objectFit: 'cover',
            alignSelf: 'center',
            filter: 'drop-shadow(5px 5px 0 #F5FBFE)',
          }}
        />
        <Stack>
          <Typography sx={contentMessageStyle(theme)}>{description}</Typography>
        </Stack>
      </Stack>
      :
      <Stack width={"100%"} justifyContent={"space-between"} direction={reverse ? "row-reverse" : 'row'} spacing={{xs:'1rem', sm: '4rem', md: '2rem'}} alignItems={'center'}>
        <Stack alignItems={'center'} spacing={'1rem'} width={'50%'}>
          <Typography width={"100%"} sx={{...contentTitleStyle(theme), textAlign:"left"}}>{title}</Typography>
          <Stack>
            <Typography sx={contentMessageStyle(theme)}>{description}</Typography>
          </Stack>
        </Stack>
        <AnimatedImage src={src} alt={alt ?? "画像"} width='46%' style={{ aspectRatio:"16/9",objectFit: 'cover',}} />
      </Stack>
    )
  }


  return (
    <Box id='service' width={'100%'}>
      <Stack 
        alignItems={'center'} 
        spacing={{
          xs: '5rem',
          sm: '3rem',
          md: '5rem'
        }}
        sx={{
          px: {
            xs: '3rem',
            sm: '5rem',
            md: '5rem',
            lg: "7rem",
            xl: "12rem", 
          },
          py: {
            xs: '2rem',
            sm: '5rem',
            md: '8rem'
          }
        }}
      >
        <Stack pt={2} direction={isMobile ? 'column':'row'} width={"100%"}>
          <Stack width={isMobile ? 'none' : '100%'} alignItems={"flex-start"} >
            <TitleWithLine title="SERVICE" width='80%'/>
            <Typography sx={{...categorySubStyle(theme), color:'#383E86', mb: { xs: '1rem', sm: '2rem', md: '3rem' }}}>私たちにできること</Typography>
            <Typography sx={{...mainTitleStyle(theme), color:'#383E86'}}></Typography>
            <Typography sx={{...mainTitleStyle(theme), color:'#383E86', whiteSpace:"pre-wrap", mb: { xs: '1rem', sm: '2rem', md: '2rem', }}}>{`「最小限のリスクで、最大限のサポートを。」`}</Typography>
            <Typography sx={{...messageStyle(theme), color:'#383E86', whiteSpace:"pre-wrap"}}>{`業界最安値の初期費用で、リスクを最小限に抑えながら海外クラウドファンディングへの出品をサポートします。${isSP ? "" : "\nプロジェクト終了まで責任を持って手厚くサポートいたします。"}`}</Typography>
        </Stack>
        
        </Stack>
        <Stack spacing={isMobile ? 10 : 15}>
          <AnimatedServiceItemMobile direction="left">
            {textComponent("1. 掲載文の構成・翻訳", service1, translate_text, !isSP ? true : undefined, "掲載文の翻訳と構成の修正の画像")}
          </AnimatedServiceItemMobile>

          <AnimatedServiceItemMobile direction="right">
            {textComponent("2. 運営・支援者とのやり取り", service2, communicate_text, !isSP ? false : undefined,  "海外の支援者とのやり取りの画像")}
          </AnimatedServiceItemMobile>
          <AnimatedServiceItemMobile direction="left">
            {textComponent("3. 海外発送のご相談", service3, delivery_text, !isSP ? true : undefined,  "海外発注の画像")}
          </AnimatedServiceItemMobile>
        </Stack>
      </Stack>


    </Box>
  );
};

export default Service;