import React, { useLayoutEffect, useRef, useState } from 'react'
import {Stack, Typography, Box,  useMediaQuery, Hidden} from '@mui/material';
import { categoryStyle, categorySubStyle, customTheme, mainTitleStyle, messageStyle } from './style/style';
import meritPNG from  '../img/merit.png'
import BannerCarousel from './Carousel/BannerCarousel';
import MeritCarouselCard from './meritCarouselCard';
import TitleWithLine from './titleWhiteLine';
import { useIsSP } from './isSP';



const Merit = () => {
  const theme = customTheme;
  const isSP = useIsSP()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const stackRef = useRef<HTMLDivElement>(null);  

  const meritCards = [
    {
      number: '01',
      title: '低リスクでの海外挑戦',
      content: isSP ? 'クラウドファンディングを活用することで、在庫を抱えることなく資金調達を行うことができます。'
                        : '海外クラウドファンディングを活用することで、在庫を抱えることなく、海外での需要予測と資金調達を同時に行うことができます。これにより、低リスクでの海外市場に挑戦をすることができます。'
    },
    {
      number: '02',
      title: '巨大な市場規模',
      content: isSP ? '海外市場は、国内市場の20倍以上の市場規模があり、国内より遥かに大きなリターンを得ることができます。'
                        : 'Kickstarterは約2,300億円、Indiegogoは約900億円の累計調達額を誇ります。また海外市場は、国内市場の20〜100倍以上の市場規模があり、より効率的にかつ国内より遥かに大きなリターンを得ることができます。'
    },
    {
      number: '03',
      title: '海外需要の把握・顧客の獲得',
      content: isSP ? '実際の需要を正確に把握しながら、海外の支援者や初期顧客との関係性を築くことができます。'
                        : '海外クラウドファンディングを行うことで、実際の需要を正確に知ることができます。また単なる資金調達に留まらず、支援者や海外における初期顧客との関係性を築くことができます。'
    },
    {
      number: '04',
      title: '国内よりも低い販売手数料',
      content: isSP ? '海外のクラウドファンディングサイトを利用することで、手数料を半額以下に抑えて掲載が可能です。'
                        :'海外クラウドファンディングは、国内のクラウドファンディングよりも販売手数料が低く、5~8 %の手数料で掲載することができます。'
    }
  ];

  const renderMeritCard = (card: any) => (
    <MeritCarouselCard
      number={card.number}
      title={card.title}
      content={card.content}
    />
  );
  const slides = meritCards.map(renderMeritCard);

  const getImageWidth = () => {
    if (isXs) return '40%';
    if (isSm) return '50%';
    if (isMd) return '40%';
    return '80%'; // デフォルトサイズ (lg以上)
  };
  const [height, setHeight] = useState<number>(0)

  useLayoutEffect(()=>{
    if(stackRef.current) setHeight(stackRef.current.offsetHeight)
  }, [stackRef.current])

  return (
    <Box position={'relative'} height={height} width={'100%'} id='merit'>

    <Box
      bgcolor={'#F5FBFE'} 
      width={'100%'}
      zIndex={1}
      top={"-300px"}
      paddingTop={"300px"}
      position={"absolute"}
      sx={{
        clipPath: `polygon(
          0% 0%,
          100% 0%,
          100% calc(100% - 10vw),
          0% 100%
        )`,
      }}
      >
      <Stack ref={stackRef} py={'5rem'} direction={'row'}
      spacing={
        isMobile
          ? {
            xs: '2rem',
            sm: '3rem',
            md: '5rem'
          }
          : undefined
      }
      sx={{
        px : isMobile 
        ? {
          xs: '1.5rem',
          sm: '5rem',
          md: '10rem'
        }
        : undefined,
        pt: '5rem',
        pb: {
          xs: '8rem',
          sm: '10rem',
          md: '15rem'
        },
        gap: {
          xs: '0.5rem',
          sm: '0.5rem',
          md: '2rem', 
          lg: '3rem',
          xl: '5rem'
        }
      }}
      >
        {!isMobile &&
          <Box
            component="img"
            src={meritPNG}
            alt={'海外クラウドファンディングのメリット'}
            sx={{
              width: getImageWidth(),
              height: 'auto', // 高さを自動調整して縦横比を維持
              maxWidth: '100%', // 画像が親要素を超えないようにする
              display: 'block', // インライン要素の余白を削除
            }}
          />
        }
        <Stack
        sx={{
          gap: {
            xs: '0.5rem',
            sm: '1rem',
            md: '2.5rem', 
            lg: '3rem',
            xl: '4rem'           
          },
          overflow: 'hidden'
        }}
        >
          <Stack>
            <TitleWithLine title="MERIT"/>

            <Typography
            sx={{...categorySubStyle(theme), color:'#383E86',
              mb: {
                xs: '1rem',
                sm: '2rem',
                md: '3rem'
              }
            }}>海外クラウドファンディングの魅力</Typography>
            <Typography sx={{...mainTitleStyle(theme), color:'#383E86',mb: {
                xs: '1rem',
                sm: '2rem',
                md: '3rem'
              }}}>
              「世界中の顧客にリーチし、
              <br/>
              国際的な認知度を高める。」
            </Typography>
          </Stack>
          <BannerCarousel slides={slides} delay={3000} isMobile={isMobile} />
        </Stack>
      </Stack>
    </Box>
    </Box>
  )
}

export default Merit;
