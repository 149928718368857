import { Stack, Typography, TextField } from '@mui/material'
import Apply from '../components/apply';


interface Props{
  isMobile: boolean
}

const ApplyPage = () => {
  return (
    <div id='apply'>
    <Stack alignItems={'center'} bgcolor={'#F6F8FA'}>
      <Apply />
    </Stack>
    </div>
  )

}

export default ApplyPage

